import axios from 'axios';
import { Injectable, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PopUpService } from 'app/services/pop-up.service';
import * as firebase from 'firebase';
declare let L;
declare let map;

declare module 'axios' {
    export interface AxiosResponse<T = any> extends Promise<T> {}
}

@Injectable({
    providedIn: 'root',
})
export class MapService {
    constructor(private http: HttpClient, private popupService: PopUpService) {}

    neighborhoods: any;
    latlng: any;
    prevZoom: any;

    vm = {
        phlGetNeighborhood: this.phlGetNeighborhood,
        neighborhoods: [],
        quickSearch: this.quickSearch,
    };

    initMap(): void {
        map = new L.map('map').setView([51.505, -0.09], 13);

        const phlCenter = {
            latitude: 39.963846,
            longitude: -75.1603751,
        };

        L.tileLayer(
            'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png',
            {
                attribution:
                    '&copy; <a href="http://osmap.org/copyright">OpenStreetMap</a> contributors',
            }
        ).addTo(map);

        this.prevZoom = map.getZoom();

        map.on('click', this.mapClick);
        map.on('zoom', this.mapZoom);

        this.reqLandUse();

        setTimeout(() => {
            /*
                in currentParcel condition, set zoom to 18
                map.zoom = 18;
            */
            map.setView([phlCenter.latitude, phlCenter.longitude], map.zoom);
        }, 2000);

        this.landUseInBlue();

        L.esri
            .featureLayer({
                url:
                    'https://services.arcgis.com/fLeGjb7u4uXqeF9q/ArcGIS/rest/services/Vacant_Indicators_Land/FeatureServer/0',
                maxZoom: 21,
                minZoom: 17,
                opacity: 0.8,
            })
            .setStyle({
                stroke: false,
                color: '#aaa',
                fillColor: '#47a801',
            })
            .addTo(map);

        L.esri
            .featureLayer({
                url:
                    'https://services.arcgis.com/fLeGjb7u4uXqeF9q/ArcGIS/rest/services/Vacant_Indicators_Bldg/FeatureServer/0',
                maxZoom: 21,
                minZoom: 17,
            })
            .setStyle({
                stroke: false,
                color: '#aaa',
                fillColor: '#eae13c',
            })
            .addTo(map);

        L.esri
            .featureLayer({
                url:
                    'https://services.arcgis.com/fLeGjb7u4uXqeF9q/ArcGIS/rest/services/Vacant_Parcels/FeatureServer/0',
                maxZoom: 21,
                minZoom: 17,
            })
            .setStyle({
                stroke: false,
                color: '#aaa',
                fillColor: '#47a801',
            })
            .addTo(map);
        this.loadTaxDelinq();
    }

    titleLayer(): void {
        return L.tileLayer(
            'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png',
            {
                attribution:
                    '&copy; <a href="http://osmap.org/copyright">OpenStreetMap</a> contributors',
            }
        ).addTo(map);
    }

    mapClick = e => {
        console.log(map.getZoom());
        console.log('e in mapClick', e);
        const a = e.latlng;
        map.panTo(e.latlng, 14);
        if (map.getZoom() > 15) {
            map.setView(e.latlng, 19);
            this.vm.quickSearch(a.lat, a.lng);
        } else {
            console.log(
                'we got into phlGetNeighborhood calling - data from a',
                a
            );
            this.vm.phlGetNeighborhood(a.lat, a.lng);

            // Show toast for Loading Neightborhood details
        }
    };

    markerClick = e => {
        console.log(e);
        const a = e.latlng;
        map.setView(a, 19);
        this.vm.quickSearch(a.lat, a.lng);
    };

    mapZoom = e => {
        if (this.prevZoom < e.target.getZoom() && this.prevZoom === 15) {
            this.vm.neighborhoods.forEach(item => {
                console.log('here at neightborhoods: ', item);
                map.removeLayer(item);
            });
        }
        this.prevZoom = e.target.getZoom();
    };

    reqLandUse(): void {
        const url = 'https://phl.carto.com:443/api/v1/map';
        const data = {
            version: '1.3.1',
            layers: [
                {
                    type: 'cartodb',
                    options: {
                        cartocss_version: '2.1.1',
                        cartocss:
                            '#layer {polygon-opacity: 0.2;polygon-fill: #fff;polygon-gamma: 0.25;line-width: 1.2;line-color: #273451; }',
                        sql: 'select * from phl.land_use',
                    },
                },
            ],
            headers: {
                headers: { 'Content-Type': 'application/json' },
            },
        };

        const dataMod = async () => {
            return axios.post(url, data).then(res => {
                const nLayer2 = L.tileLayer(
                    'https://phl.carto.com:443/api/v1/map/' +
                        res.data.layergroupid +
                        '/{z}/{x}/{y}.png',
                    {
                        attribution:
                            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                        opacity: 0.5,
                        maxZoom: 25,
                        minZoom: 15,
                    }
                );
                nLayer2.addTo(map);
            });
        };

        dataMod();
    }

    landUseInBlue(): void {
        const headers = {
            headers: { 'Content-Type': 'application/json' },
        };

        const data = {
            version: '1.3.1',
            layers: [
                {
                    type: 'cartodb',
                    options: {
                        cartocss_version: '2.1.1',
                        cartocss:
                            '#layer { polygon-fill: #009BCD;polygon-opacity: 0.25;line-width: 2.75;line-color: #FFF; }',
                        sql: 'select * from azavea.philadelphia_neighborhoods',
                    },
                },
            ],
        };

        const dataMod = async () => {
            return axios
                .post('https://azavea.carto.com/api/v1/map', data, headers)
                .then(res => {
                    console.log('the data mod: ', res.data);
                    const nLayer2 = L.tileLayer(
                        'https://azavea.carto.com/api/v1/map/' +
                            res.data.layergroupid +
                            '/{z}/{x}/{y}.png',
                        {
                            attribution:
                                '&copy; <a href="http://osmap.org/copyright">OpenStreetMap</a> contributors',
                            opacity: 0.8,
                            maxZoom: 15,
                            minZoom: 0,
                        }
                    );

                    nLayer2.addTo(map);
                })
                .catch(error => {
                    console.log(
                        'data mod error in reqLandUse.js file, ',
                        error
                    );
                });
        };

        dataMod();
    }

    phlGetNeighborhood(y, x): Promise<void> {
        const url =
            'https://us-central1-dealspace-v3.cloudfunctions.net/phlGetNeighborhood';

        const getN = () => {
            try {
                return axios({
                    method: 'GET',
                    url,
                    params: {
                        y: y,
                        x: x,
                    },
                    headers: {
                        accept: 'application/json',
                    },
                    data: {},
                });
            } catch (error) {
                console.log('ERROR: getN in map component', error);
            }
        };

        const dataMod = async () => {
            const a = this;
            getN()
                .then(res => {
                    const resp = res.data;
                    if (resp == null) {
                        console.log('Resp is null');
                        return true;
                    }
                    console.table('res here: ', resp);
                    map.setZoom(15);

                    const popupContent =
                        '<strong>' +
                        resp.rows[0].mapname +
                        '</strong> <br/> (Zoom in and click on map to view property details)';

                    const neighborhoodObject = L.geoJSON(
                        JSON.parse(resp.rows[0].st_asgeojson),
                        {
                            maxZoom: 16,
                            minZoom: 0,
                        }
                    );

                    a.neighborhoods.push(neighborhoodObject);
                    neighborhoodObject
                        .addTo(map)
                        .bindPopup(popupContent)
                        .openPopup();
                })
                .catch(error => {
                    console.log('error in dataMod calling getN', error);
                });
        };

        return dataMod();
    }

    /*
        Load tax delinq data
    */

    loadTaxDelinq(): Promise<void> {
        const url = 'https://phl.carto.com:443/api/v1/map';

        const data = {
            version: '1.3.1',
            layers: [
                {
                    type: 'cartodb',
                    options: {
                        cartocss_version: '2.1.1',
                        cartocss:
                            '#layer {marker-fill: red; marker-opacity:0.5}',
                        // 'sql': 'select%20phl.opa_properties_public.the_geom_webmercator,
                        // phl.real_estate_tax_delinquencies.the_geom_webmercator % 20from% 20phl.real_
                        // estate_tax_delinquencies,phl.opa_properties_public % 20WHERE% 20phl.real_estate_tax_
                        // delinquencies.the_geom % 20=% 20phl.opa_properties_public.the_geom %
                        // 20AND % 20phl.real_estate_tax_delinquencies.total_due % 20 >% 2015000 %
                        // 20AND % 20(phl.opa_properties_public.exterior_condition =% 276 % 27 % 20OR % 20phl.opa_properties_public.exterior_condition =% 277 % 27)'
                        sql:
                            'select phl.real_estate_tax_delinquencies.the_geom_webmercator ' +
                            'from phl.real_estate_tax_delinquencies WHERE phl.real_estate_tax_delinquencies.num_years_owed > 3',
                    },
                },
            ],
            headers: { 'Content-Type': 'application/json' },
        };

        const dataMod = async () => {
            return axios.post(url, data).then(res => {
                console.log('res from loadTaxDelinq ', res);
                const nLayer = L.tileLayer(
                    'https://phl.carto.com:443/api/v1/map/' +
                        res.data.layergroupid +
                        '/{z}/{x}/{y}.png',
                    {
                        attribution:
                            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                        maxZoom: 21,
                        minZoom: 16,
                        zIndex: 8,
                    }
                );
                nLayer.addTo(map);
            });
        };

        return dataMod();
    }

    quickSearch(lat, lng): void {
        const url =
            // 'https://us-central1-dealspace-v3.cloudfunctions.net/geoQuery';
            'https://us-central1-dealspace-1f577.cloudfunctions.net/geoquery';
        const req = {
            params: {
                lat: lat,
                lng: lng,
            },
        };

        const dataMod = async () => {
            return axios.get(url, req).then(res => {
                const resp = res.data;
                console.log('we got data in quickSearch in response', res);
                firebase
                    .database()
                    .ref()
                    .child('philadelphia')
                    .child(resp.features[0].attributes.BRT_ID)
                    .child('overlaydata')
                    .set(resp.features[0].geometry.rings[0]);

                if (resp == null) {
                    return true;
                }

                const geo = [
                    {
                        type: 'Feature',
                        properties: resp.features[0].attributes,
                        geometry: {
                            type: 'Polygon',
                            coordinates: resp.features[0].geometry.rings,
                        },
                    },
                ];

                const popupContent =
                    '<a href="/p/philadelphia/' +
                    resp.features[0].attributes.BRT_ID +
                    '">' +
                    resp.features[0].attributes.ADDRESS +
                    ' | ' +
                    resp.features[0].attributes.BRT_ID +
                    '</a><md-divider ></md-divider>' +
                    '<md-content style="">' +
                    '<p style="color:#aaa">' +
                    resp.features[0].attributes.OWNER1 +
                    '<br>' +
                    (resp.features[0].attributes.OWNER2 !== null
                        ? resp.features[0].attributes.OWNER2
                        : '') +
                    '</p>' +
                    '<p style="color:#aaa">' +
                    resp.features[0].attributes.BLDG_DESC +
                    '</p>' +
                    '<p style="color:#aaa">' +
                    resp.features[0].attributes.NUM_ACCOUNTS +
                    ' water accounts</p>' +
                    '</md-content>';

                L.geoJSON(geo)
                    .addTo(map)
                    .bindPopup(popupContent)
                    .openPopup();

                console.log('vm from auth slack');
            });
        };

        dataMod();
    }
}
