import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormsModule } from '@angular/forms';

import { CustomMaterialModule } from './core/material.module';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FirebaseModule } from './core/firebase.module';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginComponent } from './main/pages/auth/login/login.component';

import { AuthService } from './services/auth/auth.service';
import { MapService } from './main/pages/map/map.service';
import { AuthGuard } from './services/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { PropertyModule } from 'app/main/pages/property/property.module';
import { ToolbarModule } from './layout/components/toolbar/toolbar.module';
import { PagesModule } from './main/pages/pages.module';
import { AuthCheckComponent } from './main/pages/auth/login/authCheck.component';
import { PopUpService } from './services/pop-up.service';

@NgModule({
    declarations: [AppComponent, LoginComponent, AuthCheckComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // RouterModule.forRoot(appRoutes),
        FormsModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        CustomMaterialModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        PropertyModule,

        // Firebase
        FirebaseModule,
        CommonModule,
        AngularFireAuthModule,

        // Routing
        AppRoutingModule,

        // Components
        ToolbarModule,

        PagesModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthGuard,
        AuthService,
        MapService,
        AngularFireAuthGuard,
        PopUpService,
    ],
})
export class AppModule {}
