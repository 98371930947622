import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PopUpService {
    constructor() {}

    makeNeighborhoodPopup(data: any) {
        return (
            `` +
            `<div>Neighborhood: ${data.name}</div>` +
            `<div>(Zoom in and click on map to view property details)</div>`
        );
    }
}
