import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ops',
  templateUrl: './ops.component.html',
  styleUrls: ['./ops.component.scss']
})
export class OpsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
