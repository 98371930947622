import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    // user$: Observable<User>;
    public authState: EventEmitter<any>;
    constructor(
        private ngZone: NgZone,
        private afAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private router: Router
    ) {}

    public currentUser = false;
    public userStatus: string;

    public userStatusChanges: BehaviorSubject<string> = new BehaviorSubject<
        string
    >(this.userStatus);

    setUserStatus(userStatus: any): void {
        this.userStatus = userStatus;
        this.userStatusChanges.next(userStatus);
    }

    authStateChange(user: object): void {
        this.afAuth.auth.onAuthStateChanged(user => {
            console.log(user);
            if (user.uid != null) {
                // Get user info from database
                this.authState.emit(true);
            } else {
                this.authState.emit(false);
            }
        });
    }

    customLogin(token: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.signInWithCustomToken(token);
    }

    login(email: string, password: string): void {
        console.log(this.currentUser);

        this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .then(user => {
                console.log('USER', user.user.email);
                this.firestore
                    .collection('users')
                    .ref.where('email', '==', user.user.email)
                    .onSnapshot(snap => {
                        snap.forEach(userRef => {
                            console.log('userRef', userRef.data());

                            this.currentUser = true;
                            console.log(this.currentUser);
                            this.router.navigate(['/pages/sample']);
                            // if (!this.currentUser) {
                            // }
                            // this.currentUser = userRef.data();
                            // setUserStatus
                            // this.setUserStatus(this.currentUser);
                            // if (userRef.data().role !== 'admin') {
                            //     this.router.navigate(['/']);
                            // } else {
                            //     this.router.navigate(['/sample']);
                            // // }
                        });
                    });
            })
            .catch(err => err);
    }

    logOut(): void {
        this.afAuth.auth
            .signOut()
            .then(() => {
                console.log('user signed Out successfully');
                // set current user to null to be logged out
                this.currentUser = null;
                this.ngZone.run(() =>
                    this.router.navigate(['/pages/auth/login'])
                );

                console.log(this.currentUser);
            })
            .catch(err => {
                console.log(err);
            });
    }
}
