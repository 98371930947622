// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebase: {
        apiKey: 'AIzaSyBiuEKrBL7JF-Tl6NibADGM5t7LD-nd9zI',
        authDomain: 'dealspace-v3.firebaseapp.com',
        databaseURL: 'https://dealspace-v3.firebaseio.com',
        projectId: 'dealspace-v3',
        storageBucket: 'dealspace-v3.appspot.com',
        messagingSenderId: '114032512248',
        appId: '1:114032512248:web:63b38a690f27c24a'      
    },
    slack: {
        domain: 'https://slack.com/oauth/authorize',
        scopes: 'identity.basic,identity.email,identity.avatar',
        team: 'T04UXV7NP',
        client_id: '4983993771.46639829093',
        redirect_to: 'https://us-central1-dealspace-v3.cloudfunctions.net/slackAuth'
    }
};

// export const environment = {
//     production: false,
//     hmr       : false,
//     firebase: {
//         apiKey: "AIzaSyCVtHm7FODcR65c7r0hP9eVmY1L6m_FyKk",
//         authDomain: "dealspace-v3.firebaseapp.com",
//         databaseURL: "https://dealspace-v3.firebaseio.com",
//         projectId: "dealspace-v3",
//         storageBucket: "dealspace-v3.appspot.com",
//         messagingSenderId: "727264586435",
//         appId: "1:727264586435:web:e4ed01bbcd0a11693d5076"      
//     },
//     slack: {
//         domain: 'https://slack.com/oauth/authorize',
//         scopes: 'identity.basic,identity.email,identity.avatar',
//         team: 'T04UXV7NP',
//         client_id: '4983993771.46639829093',
//         redirect_to: 'https://us-central1-dealspace-v3.cloudfunctions.net/slackAuth'
//     }
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
