import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouterLink } from '@angular/router';
import { LoginComponent } from './main/pages/auth/login/login.component';
import { PropertyComponent } from './main/pages/property/property.component';
import { MapComponent } from './main/pages/map/map.component';
import { AngularFireAuthGuard, hasCustomClaim, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard'

import { PropertyService } from './main/pages/property/property.service';
import { AuthCheckComponent } from './main/pages/auth/login/authCheck.component';
import { OpsComponent } from './main/pages/ops/ops.component';
import { DashboardComponent } from './main/pages/dashboard/dashboard.component';


const redirectLoggedInToHome = () => redirectLoggedInTo(['phl/map']);
const redirectUnauthorized = () => redirectUnauthorizedTo(['auth/login'])
const routes: Routes = [
    {
        path: '*',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectLoggedInToHome}
            },
            {
                path: 'check',
                component: AuthCheckComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectLoggedInToHome}
            }
        ],
    },
    {
        path: 'pages/**',
        redirectTo: 'auth/login',
    },
    {
        path: 'map',
        redirectTo: 'phl/map',
        pathMatch: 'full',
    },
    {
        path: 'phl',
        component: MapComponent,
        children: [
            {
                path: 'map',
                component: MapComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectLoggedInToHome}
            },
            {
                path: 'p/:id',
                component: PropertyComponent,
                canActivate: [AngularFireAuthGuard],
                data: { authGuardPipe: redirectLoggedInToHome}
            },
        ]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToHome}
    },
    {
        path: 'ops',
        component: OpsComponent,
        // canActivate: [AngularFireAuthGuard],
        // data: { authGuardPipe: redirectLoggedInToHome}
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
