import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../../../services/auth/auth.service';
import { User } from '../../../../model/user.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'authCheck',
    templateUrl: './authCheck.component.html',
    styleUrls: ['./authCheck.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AuthCheckComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
    ngOnInit(): void {
        this.route.queryParamMap.subscribe(queryParams => {
            const slackToken = queryParams.get('t');
            if(slackToken){
                this.authService.customLogin(slackToken)
                .then((user) => {
                    console.log(user);
                    this.router.navigate(['/phl/map']);
                })
                .catch((err) => {
                    console.log(err);
                })
            }
        });
    }
}
