import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';

import { PropertyService } from 'app/main/pages/property/property.service';
import { PropertyComponent } from 'app/main/pages/property/property.component';
import { PropertyTimelineComponent } from 'app/main/pages/property/tabs/timeline/timeline.component';
import { PropertyAboutComponent } from 'app/main/pages/property/tabs/about/about.component';
import { PropertyPhotosVideosComponent } from 'app/main/pages/property/tabs/photos-videos/photos-videos.component';

const routes = [
    {
        path: 'profile',
        component: PropertyComponent,
        resolve: {
            profile: PropertyService,
        },
    },
];

@NgModule({
    declarations: [
        PropertyComponent,
        PropertyTimelineComponent,
        PropertyAboutComponent,
        PropertyPhotosVideosComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,

        FuseSharedModule,
    ],
    providers: [PropertyService],
})
export class PropertyModule {}
