import { Component, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent
{
    date: Date;
    deals: any[];
    notes: any[];
    settings: any;
    logOut: () => void;

    /**
     * Constructor
     */
    constructor(
        public fauth: AngularFireAuth,
        private router: Router
    )
    {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };

        this.logOut = () => {
            this.fauth.auth.signOut();
            this.router.navigate(['']);
        }
    }
}
