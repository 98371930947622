import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from './auth/login/login.component';
import { PropertyModule } from 'app/main/pages/property/property.module';
import { MapModule } from './map/map.module';
import { OpsComponent } from './ops/ops.component';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
    declarations: [OpsComponent, DashboardComponent],
    imports: [
        // RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        // Property
        PropertyModule,

        //
        MapModule,
    ],
    exports: [],
})
export class PagesModule {}
