import { Component, AfterViewInit, OnInit } from '@angular/core';
import { MapService } from './map.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
declare let L;
// declare let map;
declare var vm: any;
// declare function getNeighborhood(x: number, y: number): any;
// L.neighborhoods = [];
@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnInit {
    /**
     * Constructor
     *
     */
    neighborhood: any;
    neighborhoods = [];

    constructor(private mapService: MapService) {}

    ngAfterViewInit(): void {
        this.mapService.initMap();
    }

    ngOnInit(): void {}
}
