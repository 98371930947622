import { Component, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../../../services/auth/auth.service';
import { User } from '../../../../model/user.model';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'environments/environment';


@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit, OnChanges {
    user = new User();
    loginForm: FormGroup;
    slackstate: string;
    slackButton: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnChanges(...args: any[]): void {
        console.log(args);
    }

    ngOnInit(): void {
        if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === '127.0.0.1'
        ) {
            this.slackstate = 'dev';
        } else {
            this.slackstate = 'live';
        }
        this.slackButton = environment.slack.domain+'?scope='+environment.slack.scopes+'&team='+environment.slack.team+'&client_id='+environment.slack.client_id+'&redirect_uri='+environment.slack.redirect_to+'&state='+this.slackstate;
        
        this.route.queryParamMap.subscribe(queryParams => {
            // If the user was redirected here with slack token, forward request auth/check
            const slackToken = queryParams.get('t');
            if (slackToken) {
                this.router.navigate(['/auth/check'], {
                    queryParams: { t: slackToken },
                });
            }
        });
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });
    }

    onSubmit(): void {
        console.log('User data: ', this.user);
        this.authService.login(this.user.email, this.user.password);
        // this.loginForm.value.email = this.loginForm.value.password = '';
    }
}
