import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireDatabase } from 'angularfire2/database';

// import { AngularFireAuth } from '@angular/fire/auth';

import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

@NgModule({
    imports: [
        CommonModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireModule.initializeApp(environment.firebase),
        // AngularFireAuth
    ],
    declarations: [],
})

export class FirebaseModule {}
