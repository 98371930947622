import { Component, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'profile',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PropertyComponent {
    /**
     * Constructor
     */
    constructor() {}
}
